import { useTranslation } from "react-i18next";
import { Page } from "../components/Page";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Spacer,
  Button,
  TextCapitalized,
  capitalize,
  uppercaseFirstLetter,
  triggerToast,
  LaunchPage,
} from "@qivia/ui";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCurrentTransactionFormatted } from "../transactions/transactionPageSlice";
import { ErrorPage } from "../components/ErrorPage";
import {
  createCertificateOfHonourAsync,
  selectCreateCertificateOfHonourStatus,
  selectDocumentId,
  selectSignatureLink,
  selectUploadCertificateOfHonourStatus,
  uploadCertificateOfHonourToS3Async,
} from "./noSupportingDocumentSlice";
import { Yousign } from "./yousign";
import {
  AddressPage,
  BirthDatePage,
  DriverName,
  ExpenseTypePage,
  JobPage,
  QuantityPage,
} from "./noSupportingDocumentFormPages";
export interface SectionType {
  route: string;
}

export interface OptionType {
  value: string;
  label: string;
}

export const NoSupportingDocumentForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");
  const transactionData = useAppSelector(getCurrentTransactionFormatted);
  const createCertificateOfHonourStatus = useAppSelector(
    selectCreateCertificateOfHonourStatus,
  );
  const signatureLink = useAppSelector(selectSignatureLink);
  const documentId = useAppSelector(selectDocumentId);
  const uploadCertificateOfHonourStatus = useAppSelector(
    selectUploadCertificateOfHonourStatus,
  );

  type signatureStatus = "NULL" | "PENDING" | "DONE";
  const [waitingForSignature, setWaitingForSignature] =
    useState<signatureStatus>("NULL");
  const [expenseTypeLabel, setExpenseTypeLabel] = useState<string | null>(null);
  const [expenseTypeValue, setExpenseTypeValue] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [job, setJob] = useState<string>("");
  const [quantity, setQuantity] = useState<number | "">("");
  const [isFuelExpense, setIsFuelExpense] = useState<boolean | null>(null);

  const env = import.meta.env["VITE_TARGET_ENV"] as string;
  type EnvType = "production" | "staging" | "demo" | "localhost";

  const sections = useMemo(
    () => [
      {
        route: "expenseType",
      },
      {
        route: "quantity",
      },
      {
        route: "name",
      },
      {
        route: "birthDate",
      },
      {
        route: "address",
      },
      {
        route: "job",
      },
      {
        route: "certificateOfHonour",
      },
    ],
    [],
  );

  const [selectedSection, setSelectedSection] = useState<SectionType>(
    sections[0],
  );
  const selectedIndex = sections.findIndex(
    (section) => section.route === selectedSection.route,
  );

  const certificateOfHonourContent = useMemo(
    () =>
      t("noSupportingDocumentForm.certificateOfHonour.content", {
        name: capitalize(firstName) + " " + capitalize(lastName),
        birthDate: birthDate,
        address: address,
        job: job,
        date: transactionData?.date,
        merchantName: transactionData?.merchantName,
        expenseType: `${expenseTypeLabel}${quantity ? ", " + quantity + " L" : ""}`,
        amount: transactionData?.amount,
      }).toString(),
    [
      address,
      birthDate,
      firstName,
      job,
      lastName,
      quantity,
      t,
      transactionData?.amount,
      transactionData?.date,
      transactionData?.merchantName,
      expenseTypeLabel,
    ],
  );

  useEffect(() => {
    if (
      ["success", "error"].includes(createCertificateOfHonourStatus) &&
      transactionId
    ) {
      setWaitingForSignature("PENDING");
    }
  }, [navigate, transactionId, createCertificateOfHonourStatus, signatureLink]);

  useEffect(() => {
    if (waitingForSignature === "PENDING" && signatureLink && transactionId) {
      const yousign = new Yousign({
        signatureLink: signatureLink,
        iframeContainerId: "yousign_document",
        isSandbox: (env as EnvType) !== "production",
      });
      yousign.onSuccess((data: { signature_request_id: string }) => {
        setWaitingForSignature("DONE");
        void dispatch(
          uploadCertificateOfHonourToS3Async({
            uuid: documentId,
            yousignRequestID: data.signature_request_id,
          }),
        );
      });
    }
  }, [
    dispatch,
    documentId,
    env,
    navigate,
    signatureLink,
    transactionId,
    waitingForSignature,
  ]);

  useEffect(() => {
    if (uploadCertificateOfHonourStatus === "success") {
      triggerToast(t("certificateOfHonour.upload.success") || "", "valid");
      if (transactionId) {
        navigate(`/transaction?id=${transactionId}`);
      }
    }
    if (uploadCertificateOfHonourStatus === "failed") {
      triggerToast(t("certificateOfHonour.upload.error") || "", "error");
    }
  }, [uploadCertificateOfHonourStatus, t, navigate, transactionId]);

  const previousPage = useCallback(() => {
    if (selectedSection.route === "expenseType") {
      navigate("/no_supporting_document");
    } else if (selectedSection.route === "name" && !isFuelExpense) {
      setSelectedSection(sections[selectedIndex - 2]);
    } else {
      setSelectedSection(sections[selectedIndex - 1]);
    }
  }, [isFuelExpense, navigate, sections, selectedIndex, selectedSection.route]);

  const followingPage = useCallback(() => {
    setSelectedSection(sections[selectedIndex + 1]);
  }, [sections, selectedIndex]);

  const followingPageForCategory = useCallback(
    (isFuelCategory: boolean) => {
      setIsFuelExpense(isFuelCategory);
      setSelectedSection(sections[selectedIndex + (isFuelCategory ? 1 : 2)]);
    },
    [sections, selectedIndex],
  );

  useEffect(() => {}, [
    selectedSection.route,
    sections,
    selectedIndex,
    isFuelExpense,
  ]);

  if (transactionId === null) {
    return <ErrorPage />;
  }

  return (
    <>
      {selectedSection.route === "expenseType" ? (
        <ExpenseTypePage
          setExpenseTypeLabel={setExpenseTypeLabel}
          setExpenseTypeValue={setExpenseTypeValue}
          expenseTypeValue={expenseTypeValue}
          followingPage={followingPageForCategory}
          transactionId={transactionId}
        />
      ) : selectedSection.route === "quantity" ? (
        <QuantityPage
          quantity={quantity}
          setQuantity={setQuantity}
          previousPage={previousPage}
          followingPage={followingPage}
        />
      ) : selectedSection.route === "name" ? (
        <DriverName
          firstName={firstName}
          lastName={lastName}
          setFirstName={setFirstName}
          setLastName={setLastName}
          previousPage={previousPage}
          followingPage={followingPage}
        />
      ) : selectedSection.route === "birthDate" ? (
        <BirthDatePage
          birthDate={birthDate}
          setBirthDate={setBirthDate}
          previousPage={previousPage}
          followingPage={followingPage}
        />
      ) : selectedSection.route === "address" ? (
        <AddressPage
          address={address}
          setAddress={setAddress}
          previousPage={previousPage}
          followingPage={followingPage}
        />
      ) : selectedSection.route === "job" ? (
        <JobPage
          job={job}
          setJob={setJob}
          previousPage={previousPage}
          followingPage={followingPage}
        />
      ) : waitingForSignature === "PENDING" ? (
        <StyledYousign id="yousign_document" />
      ) : waitingForSignature === "DONE" ? (
        <LaunchPage />
      ) : (
        <Page
          title={t("noSupportingDocumentForm.certificateOfHonour")}
          bottomButton={
            <Button
              title={t("noSupportingDocumentForm.button.sign")}
              onClick={() => {
                void dispatch(
                  createCertificateOfHonourAsync({
                    transactionId,
                    originalFileName:
                      firstName +
                      "_" +
                      lastName +
                      `_${t(
                        "noSupportingDocumentForm.certificateOfHonour.fileName",
                      )}.pdf`,
                    content: uppercaseFirstLetter(certificateOfHonourContent),
                    driverInfo: {
                      firstName,
                      lastName,
                    },
                    quantity: isFuelExpense ? quantity.toString() : null,
                    fuel: isFuelExpense ? expenseTypeValue : null,
                  }),
                );
              }}
              height={3.5}
            />
          }
          returnButton
          returnButtonAction={() => previousPage()}
          returnButtonTitle={t("noSupportingDocument.button.return") || ""}
        >
          <StyledContent id="yousign_document">
            <TextCapitalized>{certificateOfHonourContent}</TextCapitalized>
            <Spacer y={2} />
            <TextCapitalized>
              {t(
                "noSupportingDocumentForm.certificateOfHonour.dateAndLocation",
              )}
            </TextCapitalized>
          </StyledContent>
        </Page>
      )}
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledYousign = styled.div`
  width: 100%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100vh;
  }
`;
